<!-- Finder Facturas Expedientes -->

<template>
  <div class="fras_Exp_F" v-if="schema && loaded" style="justify-content:center">    
    
      
      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">          
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenedor conflex" style="justify-content:center;background-color:white"> 
        <!-- Finder Expedientes -->
        <div class="columna" style="width:50%;">

          <!-- Filtro de Busqueda -->                    
          <div style="display:flex; align-items:center">
            <v-text-field
              style="flex: 0 0 40%"
              v-bind="$input"
              v-model="schema.ctrls.txt.value"
              :label="schema.ctrls.txt.label"
              append-icon="mdi-magnify"
              @keypress.13="buscarExec('ini')"
              @click:append="buscarExec('ini')">
            </v-text-field>
                        
            <v-select
              style="flex: 0 0 30%"
              v-bind="$select"
              v-model="schema.ctrls.tip.value"
              :label="schema.ctrls.tip.label"
              :items="$store.state.datos_iniciales.fact_tipo"
              item-value="d"
              item-text="n"                
              @change="buscarExec('ini')">
            </v-select>

            <v-select
              style="flex: 0 0 12%"
              v-bind="$select"
              v-model="schema.ctrls.hora.value"
              :label="schema.ctrls.hora.label"
              :items="$store.state.datos_iniciales.fact_hora"
              item-value="d"
              item-text="n"              
              @change="buscarExec('ini')">
            </v-select>

            <v-select
              style="flex: 0 0 12%"
              v-bind="$select"
              v-model="schema.ctrls.min.value"
              :label="schema.ctrls.min.label"
              :items="$store.state.datos_iniciales.fact_min"
              item-value="d"
              item-text="n"
              @change="buscarExec('ini')">
            </v-select>          
          </div>

          <div style="display:flex;padding:5px 0px 5px 0px;align-items: center;" v-if="exped">
            <!-- Botones -->
            <div style="display:flex;justify-content:space-between;width:150px;">
              <!-- Botonera Extra -->
              <btraextra
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="standard"
                @onEvent="event_btra_Top">              
              </btraextra>  

              <!-- Ventana de Documentos de Expedientes -->
              <exp_Docs              
                :id="accionRow.id"
                :exped={control:accionRow.control}>
              </exp_Docs>

              <!-- Ventana Comunicados Expedientes -->
              <exp_Comunicados              
                :id="accionRow.id"
                :accionRow="accionRow">
              </exp_Comunicados> 
            </div>    

          
            <!-- Ordenación filas Grid -->            
            <div style="display:flex;align-items: center;padding-left:15px;width:80%"> 
              {{ `Exps: ${$store.state[stName].records.length}`}}

              <v-btn                     
                v-bind="$cfg.btra_cfg.standard"            
                title="Recargar Expedientes"                        
                @click="refrescar">
                  <v-icon dark>{{ "mdi-refresh" }}</v-icon>
              </v-btn>    

              <v-select  
                style="width:30%;padding-left:10px"
                v-bind="$select"
                v-model="ordenado"
                label="Ordenado por"
                :items="itemsOrdenacion" 
                item-value="id"
                item-text="name"
                @change="ordenar">
              </v-select>

              <v-select
                style="width:20%"
                v-bind="$select"
                v-model="tipoOrden"
                label="Orden"
                :items="itemsTipoOrden" 
                item-value="id"
                item-text="name"            
                @change="ordenar">
              </v-select>

              <v-text-field
                style="width:40%"
                v-bind="$input"
                v-model="txtFiltro"
                label="Búsqueda filtrada"
                append-icon="mdi-magnify"
                @keypress.13="buscarFiltro"
                @click:append="buscarFiltro">
              </v-text-field>
            </div>
          </div>
          
          <v-sheet v-bind="$cfg.styles.marco">
            <virtual-list             
              class="fras_exp_list"
              style="height:615px; overflow-y: auto;"                       
              :data-key="'id'"              
              :data-sources="records"             
              :data-component="item"
              :estimate-size="50"
              :extra-props="{ selectedItem:selected }"
            />            
          </v-sheet>
        </div>

        <div class="columna" style="margin-left:5px;width:49%;">
          <fras_Exp_detalle            
            v-if="accionRow!= null"            
            :padre="stName"
            :id="accionRow.id"
            :auxRecord="accionRow" 
            :disparoFinder="disparo_fras_exp_detalle"
            @cierreGuardado="cierreGuardado">
          </fras_Exp_detalle>
        </div>
      </div>

      <!-- Ventana Expedientes -->
      <v-dialog
        v-model="modal"
        content-class="modal"
        persistent>
          <div class="centrado">
            <component                 
                :is="componente_dinamico"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"                
                @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''">
            </component>           
          </div>
      </v-dialog> 
   
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import fras_Exp_F_item from '@/components/fras_Exp_F_item';
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp"); 
  const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
  const fras_Exp_detalle = () => plugs.groute("fras_Exp_detalle.vue", "comp");
  const exp_M = () => plugs.groute("exp_M.vue", "comp");
  const exp_M_Demo = () => plugs.groute("exp_M_Demo.vue", "comp"); 
  const exp_Docs = () => plugs.groute("exp_Docs.vue", "comp");
  const exp_Comunicados = () => plugs.groute("exp_Comunicados.vue", "comp"); 
  
  export default {
    name:'fras_Exp_F',
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, btraextra, fras_Exp_detalle, exp_M, exp_M_Demo, exp_Docs, exp_Comunicados },
    props: {
      padre: { type:String, default: ''},
      filtro: { type:String, default: ''},
      disparoFinder: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },


    created() {
      this.$on('vsEvent', (item) => {       
        this.selected= item;
      })
    },
  

    data() {
      return {  
        schema:null,
        api:'fras_Exp_F',
        stName:'stFfras_Exp',
        accion:"0",
        accionRow: null,   
        disparo_fras_exp_detalle:false,   
        componente_dinamico:null,  
        modal:false,
        disparo:false,
        item: fras_Exp_F_item,
        ordenado:'fhhr', //'control',
        itemsOrdenacion:[],        
        tipoOrden:"0",
        itemsTipoOrden:[],
        txtFiltro:'',
        loaded:false,
      
        headers: {},
        ST_zonas: [],
        selected: {}
      };
    },


    computed: {
      records() {
        return this.$store.state[this.stName].records;
      },
      
      // hay expedientes?
      exped() {
        return this.accionRow== null? false : true;
      }
    },

    
    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno         
        this.Entorno.grid.autoload= true;
        this.Entorno.header.titulo = "Administración - Facturas Expedientes";
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo[this.Entorno.btra]));
        this.Entorno.btra.footer.extra = [
          { accion: "expedientes", btn: "btn_exp" },
          { accion: "listado_pdf", btn: "btn_pdf", title:"Listado PDF Expedientes" },
          { accion: "listado_xls", btn: "btn_excel", title:"Listado EXCEL Expedientes" },
          { accion: "expedientesDemo", btn: "btn_exp", styB:'margin-left:5px;background-color:red !important' },
          
        ];
        
        this.headers = {
          header: [
            { text: "", value: "col1", witdh: "10%", slot:true, filtro:"fechahr", ffield:"fhhr" },
            { text: "", value: "col2", witdh: "45%", slot:true, style:"width:45%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" },
            { text: "", value: "col3", witdh: "45%", slot:true, style:"width:45%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" },
          ],

        };

        this.Entorno.grid.headers = this.set_headers();


        // items adicionales
        // campos de ordenación
        this.itemsOrdenacion= [
          //{ id:"", name:'CONTROL'},
          { id:"fhhr", name:'Servicio'},
          { id:"fallename", name:'Fallecido'},
          { id:"callref1", name:'Referencia'},
          { id:"name_fune1", name:'Funeraria'},
          { id:"name_callemp", name:'Compañia'},
        ];

        // tipo de ordenación
        this.itemsTipoOrden= [
          { id:"0", name:'ASC'},
          { id:"1", name:'DESC'}
        ]
      }, 


      ini_component() { 
        this.loaded= false;
        this.schema.ctrls.txt.value= this.filtro;
        this.get_records(this.Entorno.grid.autoload? 'ini' : '');
      },


      // selecciono la primera linea del Grid
      get_records_fin() {      

        //si todos ...
        if (this.schema.ctrls.tip.value=='0') this.tipoOrden="1";

        this.txtFiltro? this.buscarFiltro() : this.ordenar();        
        this.loaded= true;
      },
      
      
      // muestro ventana expedientes
      expedientes() {        
        if (!this.accionRow) {
          this.$root.$alert.open('Debes seleccionar un expediente', 'info');
          return;
        }
        
        this.componente_dinamico= "exp_M";
        this.disparo= !this.disparo;
        this.modal= true;
      },


      expedientesDemo() {
        if (!this.accionRow) {
          this.$root.$alert.open('Debes seleccionar un expediente', 'info');
          return;
        }
        
        this.componente_dinamico= "exp_M_Demo";
        this.disparo= !this.disparo;
        this.modal= true;
      },


      // recargo finder
      refrescar (){
        this.get_records(this.Entorno.grid.autoload? 'ini' : '');
      },

      //listado de expediente en excel y pdf
      //sustituir boton con menu

      listado_pdf() {
        this.listado(1);

      },
      listado_xls() {
        this.listado(2);
      },
      //listado expedientes filtrados
      async listado(inf=1) {
        //let inf=1; //pdf,xls

        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'listado',
          //fnt:'listado', //$tip,$txt,$hor1,$hor2,$inf
          tip:this.schema.ctrls.tip.value,          
          txt:this.schema.ctrls.txt.value,
          hora:this.schema.ctrls.hora.value,
          min:this.schema.ctrls.min.value,
          inf:inf,       
        }};
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });   
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank"); 
      },


      // ordenar filas según campo seleccionado
      ordenar() {       
        var records= JSON.parse(JSON.stringify(this.$store.state[this.stName].records));
        var recordsOrdenados= [];

        // ASCENDENTE
        if (this.tipoOrden== "0") {     
          recordsOrdenados= records.sort ((a, b) => {           
            return a[this.ordenado].trim().localeCompare (b[this.ordenado].trim())             
          });
         
        // DESCENDENTE
        } else { 
          recordsOrdenados= records.sort ((a, b) =>
            b[this.ordenado].trim().localeCompare (a[this.ordenado].trim())
          );       
        }

        // actualizo records        
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:recordsOrdenados });
        
        // selecciono primer elemento
        this.selected= Object.keys(recordsOrdenados).length? recordsOrdenados[0] : {};      
      },


      // búsqueda filtrada
      buscarFiltro() {
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
        
          return (
              elem.name_callemp.toLowerCase().includes(this.txtFiltro.toLowerCase()) ||
              elem.callref1.toLowerCase().includes(this.txtFiltro.toLowerCase()) || 
              elem.name_fune1.toLowerCase().includes(this.txtFiltro.toLowerCase()) ||           
              elem.poliza.toLowerCase().includes(this.txtFiltro.toLowerCase()) ||
              elem.fallename.toLowerCase().includes(this.txtFiltro.toLowerCase()) ||
              elem.control.toLowerCase().includes(this.txtFiltro.toLowerCase())
            )
        })});

        // ordeno registros
        this.ordenar();
      },


      //  entro al guardar un cierre
      cierreGuardado() {        
        this.refrescar();
      },


      // 
      cerrar_dinamico() {
        this.modal= false;
        this.componente_dinamico= null;
      }

    },


    watch: {
      selected() {               
        this.accionRow= Object.keys(this.selected).length? this.selected : null;
        this.disparo_fras_exp_detalle= !this.disparo_fras_exp_detalle;
      }
    }

  }
</script>


